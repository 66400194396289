/** @jsx jsx */
import { jsx } from "@emotion/core";
import * as colors from '../styles/colors';

export const IconArrowLeft = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-arrow-left" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="Path" d="M9.938-10.626a1.388,1.388,0,0,1,.49-1l5.233-5.217a1.18,1.18,0,0,1,.87-.348,1.154,1.154,0,0,1,.862.348,1.194,1.194,0,0,1,.34.87,1.249,1.249,0,0,1-.379.885l-1.881,1.85-1.818,1.47,3.241-.126h7.763a1.258,1.258,0,0,1,.917.356,1.217,1.217,0,0,1,.364.909,1.27,1.27,0,0,1-.364.925,1.229,1.229,0,0,1-.917.372H16.894l-3.241-.142,1.8,1.486,1.9,1.834a1.18,1.18,0,0,1,.379.885,1.194,1.194,0,0,1-.34.87,1.154,1.154,0,0,1-.862.348,1.214,1.214,0,0,1-.87-.348L10.428-9.6A1.415,1.415,0,0,1,9.938-10.626Z" transform="translate(-2.336 26.055)"/>
      </g>
    </svg>
  )
};

export const IconArrowRight = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-arrow-right" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="Path" d="M25.938-10.626a1.388,1.388,0,0,0-.49-1L20.214-16.84a1.18,1.18,0,0,0-.87-.348,1.154,1.154,0,0,0-.862.348,1.194,1.194,0,0,0-.34.87,1.249,1.249,0,0,0,.379.885l1.881,1.85,1.818,1.47-3.241-.126H11.218a1.258,1.258,0,0,0-.917.356,1.217,1.217,0,0,0-.364.909A1.27,1.27,0,0,0,10.3-9.7a1.229,1.229,0,0,0,.917.372h7.763l3.241-.142-1.8,1.486-1.9,1.834a1.18,1.18,0,0,0-.379.885,1.194,1.194,0,0,0,.34.87,1.154,1.154,0,0,0,.862.348,1.214,1.214,0,0,0,.87-.348l5.233-5.2A1.415,1.415,0,0,0,25.938-10.626Z" transform="translate(-2.336 26.055)"/>
      </g>
    </svg>
  )
};

export const IconInstagram = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-instagram" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="path" d="M10.427,36.94a5.383,5.383,0,1,0,5.383,5.383A5.374,5.374,0,0,0,10.427,36.94Zm0,8.883a3.5,3.5,0,1,1,3.5-3.5,3.506,3.506,0,0,1-3.5,3.5Zm6.859-9.1a1.256,1.256,0,1,1-1.256-1.256A1.253,1.253,0,0,1,17.286,36.72Zm3.565,1.274a6.213,6.213,0,0,0-1.7-4.4,6.254,6.254,0,0,0-4.4-1.7c-1.733-.1-6.929-.1-8.662,0a6.245,6.245,0,0,0-4.4,1.691,6.234,6.234,0,0,0-1.7,4.4c-.1,1.733-.1,6.929,0,8.662a6.213,6.213,0,0,0,1.7,4.4,6.262,6.262,0,0,0,4.4,1.7c1.733.1,6.929.1,8.662,0a6.213,6.213,0,0,0,4.4-1.7,6.254,6.254,0,0,0,1.7-4.4C20.95,44.918,20.95,39.727,20.851,37.994ZM18.612,48.511a3.543,3.543,0,0,1-2,2c-1.382.548-4.661.422-6.189.422s-4.811.122-6.189-.422a3.543,3.543,0,0,1-2-2c-.548-1.382-.422-4.661-.422-6.189s-.122-4.811.422-6.189a3.543,3.543,0,0,1,2-2c1.382-.548,4.661-.422,6.189-.422s4.811-.122,6.189.422a3.543,3.543,0,0,1,2,2c.548,1.382.422,4.661.422,6.189S19.16,47.134,18.612,48.511Z" transform="translate(5.176 -26.886)"/>
      </g>
    </svg>
  )
};

export const IconClose = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g id="ic-arrow-left" transform="translate(-3.602 -3.359)">
        <rect id="Box" width="24" height="24" transform="translate(3.602 3.359)" fill="none"/>
        <path id="Path" d="M2.433.621A1.194,1.194,0,0,0,3,.938a1.344,1.344,0,0,0,.643,0,1.138,1.138,0,0,0,.544-.3L8.88-4.059,13.565.629a1.143,1.143,0,0,0,.553.31,1.327,1.327,0,0,0,.639,0,1.183,1.183,0,0,0,.559-.318,1.183,1.183,0,0,0,.318-.559,1.327,1.327,0,0,0,0-.639,1.158,1.158,0,0,0-.306-.553L10.647-5.826l4.685-4.685a1.174,1.174,0,0,0,.308-.553,1.286,1.286,0,0,0,0-.637,1.2,1.2,0,0,0-.321-.557,1.194,1.194,0,0,0-.561-.323,1.319,1.319,0,0,0-.637,0,1.143,1.143,0,0,0-.553.31L8.88-7.589,4.187-12.278a1.124,1.124,0,0,0-.547-.3,1.388,1.388,0,0,0-.645,0,1.14,1.14,0,0,0-.563.318,1.169,1.169,0,0,0-.31.561,1.379,1.379,0,0,0,0,.643,1.114,1.114,0,0,0,.3.542L7.113-5.826,2.42-1.125a1.143,1.143,0,0,0-.3.54,1.327,1.327,0,0,0,0,.643A1.169,1.169,0,0,0,2.433.621Z" transform="translate(6.723 21.181)"/>
      </g>
    </svg>
  )
};

export const IconCheck = ({width = '24px', height = '24px', fill = colors.black}) => {
  return (
    <svg css={{fill}} id="ic-check" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <rect id="Box" width="24" height="24" fill="none"/>
      <path id="Path" d="M5.975,77.958.258,72.241A.88.88,0,0,1,.258,71L1.5,69.753a.879.879,0,0,1,1.244,0L6.6,73.6l8.249-8.249a.88.88,0,0,1,1.244,0L17.333,66.6a.88.88,0,0,1,0,1.244L7.218,77.958a.879.879,0,0,1-1.244,0Z" transform="translate(3.41 -59.215)"/>
    </svg>
  )
};
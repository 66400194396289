/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { NavLink } from "react-router-dom";
import { ContactModal } from "./contact";
import { Logo } from "./lib";
import * as colors from "../styles/colors";
import * as mq from "../styles/media-queries";

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn(this, arguments)
    }, ms)
  }
}

const Header = () => {
  const [isOpen, setIsOpen] = React.useState('closed');
  const [headerState, setHeaderState] = React.useState('active');
  const [modalState, setModalState] = React.useState('closed');

  if(isOpen === 'open') {
    document.body.classList.add('no-scroll')
  } else if(isOpen === 'closed') {
    document.body.classList.remove('no-scroll')
  }

  function toggleOpen() {
    setIsOpen(isOpen === 'open' ? 'closed' : 'open');
  }

  function handleModal() {
    const nextState = modalState === 'closed' ? 'open' : 'closed';
    setModalState(nextState);
  }

  React.useEffect(() => {
    const debounceHandleResize = debounce(
      function handleResize() {
        if(isOpen === 'closed' || window.innerWidth < 880) return
        setIsOpen('closed')
      }, 400)

    window.addEventListener('resize', debounceHandleResize)
    return () => window.removeEventListener('resize', debounceHandleResize)
  }, [isOpen])

  React.useEffect(() => {
    let lastScroll = 0;
    function handleScroll() {
      const {scrollY} = window;
      if(scrollY < 100 || scrollY < lastScroll - 20) setHeaderState('active');
      if(scrollY > 150 && scrollY > lastScroll + 10) setHeaderState('hidden');
      lastScroll = scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);;


  return (
    <header css={{
      backgroundColor: colors.white,
      width: '100%',
      position: 'fixed',
      top: headerState === 'active' ? '0' : '-100px' ,
      left: '0',
      zIndex: '5',
      transition: 'top ease .4s'
    }}>
      <div css={
        {
          overflow: 'hidden',
          height: isOpen === 'open' ? '100vh' : '66px',
          width: '100vw',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '12px 20px',
          boxSizing: 'border-box',
          backgroundColor: colors.white,
          transition: 'height ease-out .4s'
        }}>
        <h1 css={{textIndent: '-999px', height: 0, width: 0}}>CH Proyecta</h1>
        <div css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          '.nav-desktop': {
            display: 'none',
          },
          [mq.large]: {
            '& button': {
              display: 'none',
            },
            '.nav-desktop': {
              display: 'block',
            }
          }
        }}>
          <NavLink to='/'>
            <div css={{paddingBottom: '5px'}}>
              <Logo/>
            </div>
          </NavLink>
          <NavDesktop handleModal={handleModal}/>
          <button 
            className='nav-mobile-button'
            onClick={toggleOpen}
            css={{
              padding: '10px 10px',
              background: 'none',
              border: 'none',
              fontSize: '16px',
              fontWeight: '600',
              color: colors.gray30
            }}
          >
            {isOpen === 'open' ? 'CERRAR' : 'MENU'}
          </button>
        </div>
        <NavMobile handleModal={handleModal}/>
        <ContactModal handleModal={handleModal} modalState={modalState}/>
      </div>
    </header>
  )
};

const NavMobile = ({handleModal}) => {
  return(
    <div className='nav-mobile'>
      <div>
        <ul css={{
          marginTop: '80px',
          textAlign: 'center',
          listStyle: 'none',
          '& li': {
            marginBottom: '52px',
            fontFamily: '"Satoshi", sans-serif',
            fontSize: '24px',
            '& a':  {
              color: colors.gray30,
              textDecoration: 'none',
            },
            '& .active': {
              color: colors.gray87,
              fontSize: '30px',
              fontWeight: 600,
            }
          }
        }}>
          <li><NavLink to='/proyectos'>Proyectos</NavLink></li>
          <li><NavLink to='/nosotros'>Nosotros</NavLink></li>
          <li onClick={handleModal} 
            css={{
              color: colors.blueJeans, 
              [mq.hover]: {
                cursor: 'pointer',
                ':hover':{
                  color: colors.carolinaBlue,
                }
              }
            }}
          >Contacto</li>
        </ul>
      </div>
    </div>
  )
}

const NavDesktop = ({handleModal}) => {
  return (
    <div className='nav-desktop'>
      <ul css={{
        display: 'flex',
        fontFamily: '"Satoshi", sans-serif',
        fontWeight: 400,
        listStyle: 'none',
        '& li': {
          marginRight: '40px',
          '& .active': {
            color: colors.gray87,
            fontWeight: 700,
          },
          '& a':  {
            color: colors.gray30,
            textDecoration: 'none',
            transition: 'color ease .4s',
            [mq.hover]: {
              ':hover': {
                color: colors.gray87
              }
            }
          },
        }
      }}>
        <li><NavLink to='/proyectos'>Proyectos</NavLink></li>
        <li><NavLink to='/nosotros'>Nosotros</NavLink></li>
        <li 
          onClick={handleModal}
          css={{
            color: colors.blueJeans, 
            transition: 'color ease .4s',
            [mq.hover]: {
              cursor: 'pointer',
              ':hover':{
                color: colors.carolinaBlue,
              }
            }
          }}
        >Contacto</li>
      </ul>
    </div>
  );
}

export default Header;

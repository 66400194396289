/** @jsx jsx */
import { jsx } from "@emotion/core"
import projectsData from "../data/projects-data.json"
import Header from "../components/Header"
import { Button, H2, P } from "../components/lib"
import { HomeSwiper } from "../components/slider"
import { ProjectsHome } from "../components/project"
import { ContactSection } from "../components/contact"
import Footer from "../components/Footer"
import { ScrollRestoration } from "react-router-dom"
import * as mq from "../styles/media-queries"

export default function HomeScreen() {
  function handleScroll() {
    const element = document.querySelector('#contact')
    if(element) setTimeout(() => element.scrollIntoView({behavior: "smooth"}), 0)
  }

  return (
    <div>
      <ScrollRestoration/>
      <Header/>
      <div css={{
        maxWidth: '1200px',
        padding: '0 16px',
        margin: '80px auto 100px',
      }}>
        <HomeSwiper/>
      </div>
      <div css={{
        padding: '0 30px',
        maxWidth: '1200px',
        margin: '0 auto',
        [mq.medium]: {
          padding: '0 50px',
        }
      }}>
        <div css={{
          marginBottom: '140px',
          [mq.medium]: {
            textAlign: 'center'
          }
        }}>
          <div css={{
            maxWidth: '850px',
            margin: '0 auto 36px',
            [mq.medium]: {
              '& h2': {
                fontSize: '30px'
              }, 
              '& p':  {
                fontSize: '18px'
              }
            },
            [mq.large]: {
              '& h2': {
                fontSize: '40px'
              }, 
              '& p':  {
                fontSize: '20px'
              }
            }
          }}>
            <H2 size='40px'>Creamos espacios únicos para habitar.</H2>
            <P size="20px">Nos especializamos en diseño y construcción de proyectos residenciales. Trabajamos en proyectos propios, y de nuestros clientes.</P>
          </div>
         <Button onClick={handleScroll}>Contáctanos</Button>
        </div>
        <div css={{marginBottom: '150px'}}>
          <ProjectsHome projects={projectsData}/>
        </div>
      </div>
      <ContactSection id="contact"/>
      <Footer/>
    </div>
  )
}
export const black = "#000000";
export const white = "#FFFFFF";
export const white50 = "rgba(255, 255, 255, 0.5)"
export const white80 = "rgba(255, 255, 255, 0.8)"
export const gray87 = "#212121";
export const gray54 = "#757575";
export const gray30 = "#B3B3B3";
export const gray10 = "#E6E6E6";
export const blueJeans = "#3FADFA";
export const carolinaBlue = "#199EFC";
export const tartOrange = '#FF312E';
export const cultured = '#F7F8FA';
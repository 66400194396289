/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { SaleTag } from './lib';
import * as mq from '../styles/media-queries';
import * as colors from '../styles/colors';

const cardProjectVariants = {
	down: {
		'& .card-project .title-container': {
			flexDirection: 'column-reverse',
			padding: '20% 6% 6% 10%',
			bottom: '0',
			top: 'unset',
			borderRadius: '0px 0px 5px 2px',
			background: [
				'rgb(0,0,0)',
				'linear-gradient(0deg, rgba(0,0,0,0.68) 0%, rgba(0,0,0,0) 100%)',
			],
			'& span': {
				marginTop: '10%',
				marginBottom: '0',
			},
		},
	},
	projectDetail: {
		height: '90vw',
		maxHeight: '450px',
		'& .sale-tag': {
			padding: '5px 10px',
			fontSize: '14px',
		},
		'& .card-project .date': {
			fontSize: '12px',
		},
		'& .card-project .title': {
			fontSize: '34px',
		},
		'& .card-project-caption': {
			fontSize: '14px',
		},
	},
};

const cardProjectSizes = {
	medium: {
		height: '35vw',
		maxHeight: '330px',
		'& .sale-tag': {
			padding: '5px 8px',
			fontSize: '10px',
			top: '10px',
		},
		'& .card-project .date': {
			fontSize: '14px',
		},
		'& .card-project .title': {
			fontSize: '30px',
		},
		'& .card-project-caption': {
			fontSize: '16px',
		},
	},
	large: {
		height: '37vw',
		maxHeight: '360px',
		'& .sale-tag': {
			padding: '5px 10px',
			fontSize: '12px',
		},
		'& .card-project .date': {
			fontSize: '14px',
		},
		'& .card-project .title': {
			fontSize: '34px',
		},
		'& .card-project-caption': {
			fontSize: '16px',
		},
	},
	xLarge: {
		height: '37vw',
		maxHeight: '450px',
		'& .sale-tag': {
			padding: '6px 12px',
			fontSize: '16px',
		},
		'& .card-project .date': {
			fontSize: '14px',
		},
		'& .card-project .title': {
			fontSize: '38px',
		},
		'& .card-project-caption': {
			fontSize: '18px',
		},
	},
};

const MobileSVG = () => {
	return (
		<svg
			css={{ width: '100%', height: 'auto' }}
			id="Brillos2"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="320"
			height="470"
			viewBox="0 0 320 470"
		>
			<defs>
				<linearGradient
					id="linear-gradient2"
					x1="-0.5"
					y1="0.5"
					x2="1"
					y2="0.5"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor="#fff" />
					<stop offset="1" stopColor="#fff" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-22"
					x1="-0.5"
					y1="0.5"
					x2="1"
					y2="0.5"
					xlinkHref="#linear-gradient2"
				/>
				<linearGradient
					id="linear-gradient-32"
					x1="-0.5"
					y1="0"
					x2="-0.5"
					y2="2"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor="#fff" stopOpacity=".5" />
					<stop offset="0.5" stopColor="#fff" stopOpacity="0" />
					<stop offset="1" stopColor="#fff" stopOpacity="0" />
				</linearGradient>
			</defs>
			<rect
				id="Brillo_Lateral2"
				data-name="Brillo Lateral"
				width="7"
				height="470"
				transform="translate(15)"
				opacity="0.369"
				fill="url(#linear-gradient2)"
			/>
			<rect
				id="Brillo_Lateral-22"
				data-name="Brillo Lateral"
				width="5"
				height="470"
				opacity="0.46"
				fill="url(#linear-gradient-22)"
			/>
			<rect
				id="Brillo2"
				width="320"
				height="5"
				opacity="1"
				fill="url(#linear-gradient-32)"
			/>
		</svg>
	);
};

const DesktopSVG = () => {
	return (
		<svg
			css={{ width: '100%', height: 'auto' }}
			id="Brillos"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="320"
			height="470"
			viewBox="0 0 320 470"
		>
			<defs>
				<linearGradient
					id="linear-gradient"
					x1="-0.5"
					y1="0.5"
					x2="1"
					y2="0.5"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor="#fff" />
					<stop offset="1" stopColor="#fff" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="linear-gradient-2"
					x1="-0.5"
					y1="0.5"
					x2="1"
					y2="0.5"
					xlinkHref="#linear-gradient"
				/>
				<linearGradient
					id="linear-gradient-3"
					x1="-0.5"
					y1="0"
					x2="-0.5"
					y2="2"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor="#fff" stopOpacity=".5" />
					<stop offset="0.5" stopColor="#fff" stopOpacity="0" />
					<stop offset="1" stopColor="#fff" stopOpacity="0" />
				</linearGradient>
			</defs>
			<rect
				id="Brillo_Lateral"
				data-name="Brillo Lateral"
				width="7"
				height="470"
				transform="translate(15)"
				opacity="0.369"
				fill="url(#linear-gradient)"
			/>
			<rect
				id="Brillo_Lateral-2"
				data-name="Brillo Lateral"
				width="5"
				height="470"
				opacity="0.46"
				fill="url(#linear-gradient-2)"
			/>
			<rect
				id="Brillo"
				width="320"
				height="5"
				opacity="1"
				fill="url(#linear-gradient-3)"
			/>
		</svg>
	);
};

export const CardProject = ({
	size = '',
	desktop = false,
	project: {
		id,
		name,
		caption,
		coverPhoto: { url, variant },
		status: { dateEnd },
		sale: { inSale },
	},
}) => {
	return (
		<Link
			to={`/proyectos/${id}`}
			className="card-project"
			css={[
				{
					height: '110vw',
					aspectRatio: '59 / 86',
					display: 'grid',
					position: 'relative',
					transition: 'transform ease .3s',
					[mq.hover]: {
						':hover': {
							transform: 'scale(1.08)',
						},
					},
				},
				cardProjectVariants[variant],
				{
					[mq.medium]: {
						...cardProjectSizes['medium'],
					},
				},
				{
					[mq.large]: {
						...cardProjectSizes['large'],
					},
				},
				{
					[mq.xLarge]: {
						...cardProjectSizes['xLarge'],
					},
				},
				cardProjectSizes[size],
			]}
		>
			{inSale && (
				<SaleTag
					className={`sale-tag`}
					css={{
						position: 'absolute',
						top: '10px',
						left: '-20px',
						zIndex: '3',
						transition: 'all ease .3s',
					}}
				>
					En Venta
				</SaleTag>
			)}
			<div
				className="card-project"
				css={{
					overflow: 'hidden',
					width: '100%',
					height: '100%',
					borderRadius: '2px 5px 5px 2px',
					position: 'relative',
					boxShadow: '0px 30px 60px rgba(0, 0, 0, 0.16)',
				}}
			>
				<div
					className="title-container"
					css={{
						position: 'absolute',
						top: '0',
						left: '0',
						width: '100%',
						padding: '7% 5% 10% 10%',
						color: 'white',
						textShadow: '0px 0px 16px rgba(0, 0, 0, 0.6)',
						boxSizing: 'border-box',
						backgroundColor: 'orange',
						display: 'flex',
						flexDirection: 'column',
						borderRadius: '2px 5px 0px 0px',
						zIndex: 2,
						background: [
							'rgb(0,0,0)',
							'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.68) 100%)',
						],
						'& h4': {
							fontSize: '38px',
							marginBottom: '4px',
							transition: 'font-size ease .3s',
						},
						'& p': {
							fontSize: '18px',
							transition: 'font-size ease .3s',
							color: colors.white80,
						},
						'& span': {
							width: '100%',
							textAlign: 'right',
							fontSize: '16px',
							fontWeight: '600',
							marginBottom: '10%',
							transition: 'font-size ease .3s',
						},
					}}
				>
					<span className="date">{new Date(dateEnd).getFullYear()}</span>
					<div>
						<h4 className="title">{name.toUpperCase()}</h4>
						<p className="card-project-caption">{caption}</p>
					</div>
				</div>
				<figure
					css={{
						width: '100%',
						position: 'absolute',
						top: '-.5px',
						left: '0',
						zIndex: '2',
					}}
				>
					{desktop ? <DesktopSVG /> : <MobileSVG />}
				</figure>
				<figure
					css={{
						width: '100%',
						position: 'absolute',
						top: '0',
						left: '0',
						display: 'contents',
					}}
				>
					<img
						css={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
						}}
						src={url}
						alt={name}
					/>
				</figure>
			</div>
		</Link>
	);
};

/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router';
import { ScrollRestoration } from 'react-router-dom';
import { CardProject } from '../components/cards';
import Footer from '../components/Footer';
import { IconClose } from '../components/icons';
import { ButtonCircle } from '../components/lib';
import {
	ProjectDescription,
	ProjectGallery,
	ProjectHeader,
} from '../components/project';
import projectsData from '../data/projects-data.json';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';

export const loader = ({ params }) => {
	const { projectID } = params;
	const project = projectsData.find((project) => project.id === projectID);
	return project;
};

// Debounce function to delay execution
function debounce(callback, delay) {
	let timeoutId;
	return function (...args) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			callback(...args);
		}, delay);
	};
}

function CardProyectContainer({ project }) {
	const initialScale = 1;
	const minimunScale = 0.8;
	const cardProject = React.useRef();
	const [scale, setScale] = React.useState(initialScale);

	React.useEffect(() => {
		const handleScroll = () => {
			const { scrollY } = window;
			// * Step for every for every scroll value divided 0.5 to make to make it longer
			const step2 = 1 - (scrollY / 1000) * 0.5;

			setScale(step2 < minimunScale ? minimunScale : step2);
		};

		// DEBOUNCE - To make animation smoother 10ms (beacuse to reach 60 frames/s we need it to update every 16ms)
		const debouncedScrollHandler = debounce(() => {
			handleScroll();
		}, 10);

		window.addEventListener('scroll', debouncedScrollHandler);
		return () => {
			window.removeEventListener('scroll', debouncedScrollHandler);
		};
	}, []);

	return (
		<div
			ref={cardProject}
			css={{
				maxWidth: '1200px',
				padding: '120px 60px 80px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-end',
				position: 'sticky',
				top: 0,
				zIndex: -1,
				transform: `scale(${scale})`,
				transition: 'transform 0.1s ease',
				[mq.medium]: {
					'& .card-project': {
						height: '85vh',
						maxHeight: '450px',
						'& .sale-tag': {
							padding: '6px 12px',
							fontSize: '16px',
						},
						'& .card-project .date': {
							fontSize: '14px',
						},
						'& .card-project .title': {
							fontSize: '38px',
						},
						'& .card-project-caption': {
							fontSize: '16px',
						},
					},
				},
			}}
		>
			<CardProject project={project} variant="projectDetail" />
		</div>
	);
}

const ProjectScreen = () => {
	const location = useLocation();

	function handleBack() {
		if (location.key === 'default') {
			navigate('/proyectos');
		} else {
			navigate(-1);
		}
	}

	const project = useLoaderData();

	const navigate = useNavigate();

	return (
		<div>
			<ScrollRestoration />
			<ProjectHeader project={project} />
			<div
				css={{
					width: '100%',
					position: 'fixed',
					top: '0',
					left: '0',
				}}
			>
				<div
					css={{
						width: '100%',
						height: '72px',
						maxWidth: '1180px',
						margin: '0 auto',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<ButtonCircle css={{ marginRight: '16px' }} onClick={handleBack}>
						<IconClose fill={colors.white} />
					</ButtonCircle>
				</div>
			</div>
			<div
				css={{
					[mq.large]: {
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'center',
					},
				}}
			>
				<CardProyectContainer project={project} />
				<div
					css={{
						backgroundColor: colors.white,
					}}
				>
					<ProjectDescription project={project} />
				</div>
			</div>
			<ProjectGallery photos={project.photos} />
			<Footer />
		</div>
	);
};

export default ProjectScreen;

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import * as colors from "../styles/colors";
import * as mq from "../styles/media-queries";

const NotFoundScreen = () => {
  return (
    <div>
      <Header/>
      <div css={{
        maxWidth: '1080px',
        height: '90vh',
        maxHeight: '950px',
        margin: '0 auto',
        padding: '0 30px 30px',
        fontWeight: '600',
        fontSize: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [mq.medium]: {
          fontSize: '40px',
        },
        [mq.large]: {
          fontSize: '44px'
        },
        [mq.xLarge]: {
          fontSize: '50px'
        }
      }}>
        <div css={{
          '& p': {
            marginBottom: '16px',
            fontFamily: '"Poppins", sans-serif',
            textAlign: "center",
            ' & a': {
              color: colors.blueJeans 
            }
          }
        }}>
          <p>¿Perdido?</p>
          <p> Mejor no te pierdas nuestros <span><Link to="/proyectos">proyectos.</Link></span></p>
        </div>
      </div>
      <Footer/>
    </div>
  )
};

export default NotFoundScreen;

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ScrollRestoration } from 'react-router-dom';
import { ContactSection } from '../components/contact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { BoxOutline, H2, H3, P } from '../components/lib';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';

const UsScreen = () => {
	return (
		<div>
			<ScrollRestoration />
			<Header />
			<div
				css={{
					width: '90%',
					height: '80vh',
					maxWidth: '1200px',
					maxHeight: '600px',
					margin: '100px auto 40px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<BoxOutline
					css={{
						padding: '40px 20px',
						display: 'flex',
						alignItems: 'center',
						'& h2': {
							fontSize: '32px',
							'& span': {
								color: colors.blueJeans,
							},
						},
						[mq.medium]: {
							padding: '40px',
						},
						[mq.large]: {
							'& h2': {
								fontSize: '44px',
							},
						},
					}}
				>
					<H2>
						Somos un estudio creado hace 30 años por el Arquitecto Carlos
						Hernández Partida. Enfocado en proyectar y construir experiencias de{' '}
						<span>vida a través de la arquitectura y el interiorismo.</span>
					</H2>
				</BoxOutline>
			</div>
			<div
				css={{
					maxWidth: '1200px',
					margin: '0 auto',
					padding: '30px',
					[mq.medium]: {
						padding: '0 50px',
					},
				}}
			>
				<div
					css={{
						maxWidth: '800px',
						margin: '0 auto 140px',
						'& p': {
							marginBottom: '40px',
						},
						[mq.large]: {
							marginBottom: '200px',
							'& h3': {
								fontSize: '30px',
							},
							'& p': {
								marginBottom: '60px',
								fontSize: '20px',
							},
						},
					}}
				>
					<div>
						<H3 size="22px">Arquitectura</H3>
						<P>
							Nuestra propuesta arquitectónica ha sido influenciada por
							diferentes corrientes y culturas a través de los años; dando como
							resultado proyectos armoniosos y funcionales que generan espacios
							que emocionan al espectador. Utilizando materiales naturales que
							se integran al entorno.
						</P>
					</div>
				</div>
			</div>
			<ContactSection />
			<Footer />
		</div>
	);
};

export default UsScreen;

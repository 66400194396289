/** @jsx jsx */
import { jsx } from "@emotion/core";
import { IconInstagram } from "./icons";
import { Caption, FlexColumn, Logo, P } from "./lib";
import * as colors from "../styles/colors";
import * as mq from "../styles/media-queries";

const Footer = () => {
  return (
    <footer css={{
      marginTop: '100px',
      borderTop: `solid 1px ${colors.gray10}`,
    }}>
      <div css={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '36px 30px 10px',
        boxSizing: 'border-box',
        '&  > div': {
          marginBottom: '40px',
        },
        [mq.medium]: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }
      }}>
        <FlexColumn css={{
          marginBottom: '40px',
          '& > svg': {
            marginBottom: '20px',
          },
        }}>
          <Logo/>
          <P size={'14px'}>CH Proyecta, SA de CV®. </P>
          <P size={'14px'}>Todos los derechos reservados. © {new Date().getFullYear()}</P>
        </FlexColumn>
        <FlexColumn>
          <Caption fColor={colors.gray87}>Contacto</Caption>
          <P size={'14px'}>info@chproyecta.com</P>
        </FlexColumn>
        <FlexColumn css={{marginRight: '16px'}}>
          <Caption fColor={colors.gray87}>Redes sociales</Caption>
          <a href="https://instagram.com/chproyecta_?igshid=YTY2NzY3YTc=" target="_blank" rel="noreferrer">
            <IconInstagram fill={colors.gray54}/>
          </a>
        </FlexColumn>
      </div>
    </footer>
  )
};

export default Footer;
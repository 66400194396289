/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from 'react';
import { BoxOutline, Caption, FlexColumn, P, H3, LogoIcon, ButtonCircle } from "./lib";
import { FormContact } from "./form";
import { IconClose } from "./icons";
import * as colors from "../styles/colors";
import * as mq from "../styles/media-queries";


export const ContactCard = () => {
  return (
    <BoxOutline 
      className="contact-card"
      css={{
        backgroundColor: colors.white,
        minWidth: '240px',
        height: 'fit-content',
        [mq.medium]: {
          width: '40%'
        }
      }}
    >
      <FlexColumn css={{
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '22px 22px 36px'
      }}>
        <FlexColumn css={{
        alignItems: 'center',
        marginBottom: '40px'
        }}>
          <figure css={{
            width: '60px',
            height: '60px',
            backgroundColor: colors.cultured,
            borderRadius: '50px',
            marginBottom: '22px'
          }}>
            <LogoIcon/>
          </figure>
          <H3 fontSize='18px'>CH Proyecta</H3>
        </FlexColumn>
        <FlexColumn css={{width: '100%'}}>
          <FlexColumn css={{
          marginBottom: '18px'
          }}>
            <Caption fColor={colors.gray87}>Email</Caption>
            <P>info@chproyecta.com</P>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </BoxOutline>
  )
};

export const ContactSection = ({id}) => {
  return (
    <div 
      id={id}
    css={{
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 30px',
      [mq.large]: {
        padding: '0 50px',
      }
    }}>
      <div css={{
        marginBottom: '56px',
      }}>
        <H3>Contáctanos</H3>
        <P>Construimos residencias a la medida y necesidades de nuestros clientes.</P>
      </div>
      <div css={{
        marginBottom: '112px',
        '> div:first-of-type': {
          marginBottom: '20px',
        },
        [mq.medium]: {
          display: 'flex',
          justifyContent: 'space-evenly',
          '& .form-contact': {
            width: '58%',
            maxWidth: '400px',
          },
          '& .contact-card':{
            maxWidth: '250px',
          },
        },
        [mq.large]: {
          justifyContent: 'space-evenly',
        }
      }}>
        <ContactCard/>
        <FormContact/>
      </div>
    </div>
  )
};


export const ContactModal = ({modalState, handleModal}) => {
  if(modalState === 'open') {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
  
  return (
    <div css={[
      {
        width: '100vw',
        height: '100vh',
        paddingTop: '40px',
        position: 'fixed',
        top: 0,
        left: 0,
        backdropFilter: 'blur(50px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: '6',
        overflowY: 'scroll',
        opacity: '0',
        pointerEvents: 'none',
        transition: 'opacity ease 0.4s',
      },
      modalState === 'open' ? {
        opacity: '1',
        pointerEvents: 'all',
      } : null
    ]}>
      <div css={{
        maxWidth: '1200px',
        display: 'flex', 
        justifyContent: 'flex-end',
        margin: '0 auto 40px',
      }}>
        <ButtonCircle onClick={handleModal} css={{marginRight: '30px', alignSelf: 'right'}}>
          <IconClose fill="white"/>
        </ButtonCircle>
      </div>
      <ContactSection/>
    </div>
  )
};


export const ContactModalChildren = ({children}) => {
  const [modalState, setModalState] = React.useState('closed');

  function handleModal() {
    const nextState = modalState === 'closed' ? 'open' : 'closed';
    setModalState(nextState);
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
    document.body.style.marginRight = modalState === 'closed' ? (scrollbarWidth + 'px') :  null; 
  }

  if(modalState === 'open') {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
  
  return (
    <>
      <div css={[
        {
          width: '100vw',
          height: '100vh',
          paddingTop: '40px',
          position: 'fixed',
          top: 0,
          left: 0,
          backdropFilter: 'blur(50px)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          zIndex: '6',
          overflowY: 'scroll',
          opacity: '0',
          pointerEvents: 'none',
          transition: 'opacity ease 0.4s',
        },
        modalState === 'open' ? {
          opacity: '1',
          pointerEvents: 'all',
        } : null
      ]}>
        <div css={{
          maxWidth: '1200px',
          display: 'flex', 
          justifyContent: 'flex-end',
          margin: '0 auto 40px',
        }}>
          <ButtonCircle onClick={handleModal} css={{marginRight: '30px', alignSelf: 'right'}}>
            <IconClose fill="white"/>
          </ButtonCircle>
        </div>
        <ContactSection/>
      </div>
      <div onClick={handleModal}>
        {children}
      </div>
    </>
  )
};
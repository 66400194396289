/** @jsx jsx */
import {jsx} from '@emotion/core';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import HomeScreen from './screens/home';
import ProjectsScreen from './screens/Projects';
import ProjectScreen, {loader as projectLoader} from './screens/Project';
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom';
import UsScreen from './screens/Us';
import NotFoundScreen from './screens/NotFound';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<HomeScreen/>}/>
      <Route path='/proyectos' element={<ProjectsScreen/>}/>
      <Route path='/proyectos/:projectID' loader={projectLoader} element={<ProjectScreen/>}/>
      <Route path='/nosotros' element={<UsScreen/>}/>
      <Route path='/*' element={<NotFoundScreen/>}/>
    </>
  )
);

function App() {
  return (
    <RouterProvider router={router}/>
  )
};

export default App;

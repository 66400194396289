/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { Keyboard, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IconArrowLeft, IconArrowRight } from "./icons";
import { ButtonCircle } from "./lib";
import * as colors from "../styles/colors";
import * as mq from "../styles/media-queries";

const slideVariants = {
  prev: {
    zIndex: '2',
    transform: 'translateX(-100%)',
  },
  current: {
    zIndex: '2',
    transform: 'translateX(0)',
  },
  next: {
    zIndex: '2',
    transform: 'translateX(100%)',
  },
  afterNext: {
    zIndex: '1',
    transform: 'translateX(200%)',
  }
}

const Slide = ({slide, variant, className}) => {
  return (
    <div css={[
      {
        background: slide.color,
        display: 'flex',
        position: 'absolute',
        height: '100%',
        width: '100%',
        alignContent:' center',
        justifyContent:' center',
        color: 'white',
        fontSize:' 100px',
        fontFamily:' sans-serif',
        transition: 'all ease .4s',
        transform: 'translateX(-200%)',
      }, 
      slideVariants[variant]
    ]}
    >
        <figure css={{
          height: '100%'
        }}>
          <img
            css={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: '80% 10%'
            }} 
            src="https://res.cloudinary.com/carlos-hm/image/upload/v1662742347/ch-proyecta/slider/farallon-portada_tiorwq.jpg" alt="Farallon"/>
        </figure>
    </div>
  )
}

const Slides = styled.div({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    position: 'relative',
    margin: '0 auto',
    overflow: 'hidden',
});

export const Slider = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slides = [
    {num: 0, color: 'purple'},
    {num: 1, color: 'red'},
    {num: 2, color: 'green'},
    {num: 3, color: 'blue'},
    {num: 4, color: 'orange'},
    {num: 5, color: 'yellow'},
    {num: 6, color: 'pink'},
  ];
  const isFirst = currentSlide === 0;
  const isLast = currentSlide === slides.length - 1;
  const prev = currentSlide - 1;
  const next = currentSlide + 1;
  const afterNext = currentSlide + 2;
  

  function nextSlide() {
    if(isLast) return
    setCurrentSlide(currentSlide + 1);
  }

  function prevSlide() {
    if(isFirst) return
    setCurrentSlide(currentSlide - 1);
  }

  return (
    <div 
      css={{
        height:'88vh',
        boxSizing:'border-box',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <div css={{
        width: '80px',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        zIndex: 4,
        bottom: '20px',
        right: '20px',
      }}>
        <ButtonCircle onClick={prevSlide} disabled={isFirst} variant={isFirst ? 'disabled' : ''}>
          <IconArrowLeft fill={colors.white50}/>
        </ButtonCircle>
        <ButtonCircle onClick={nextSlide} disabled={isLast} variant={isLast ? 'disabled' : ''}>
          <IconArrowRight fill={colors.white50}/>
        </ButtonCircle>
      </div>
      <Slides>
        {slides.map((slide, i) => <Slide key={slide.num} slide={slide} variant={i === currentSlide ? 'current' : i === prev ? 'prev' : i === next ? 'next' : i === afterNext ? 'afterNext' : ''}/>)}
      </Slides>
    </div>
  )
};


export const HomeSwiper = () => {
  const photos = [
    {id: 'zt9ztwrjbsr6gwhe8lqe',url: 'https://res.cloudinary.com/carlos-hm/image/upload/v1670291686/chproyecta/proyectos/lluvia02/zt9ztwrjbsr6gwhe8lqe.jpg'},
    {id: 'muktu3bnwpmihljrmtgj',url: 'https://res.cloudinary.com/carlos-hm/image/upload/v1667253757/chproyecta/proyectos/iglesia/muktu3bnwpmihljrmtgj.jpg'},
    {id: 'wtbtqdwbwra29goovo7d',url: 'https://res.cloudinary.com/carlos-hm/image/upload/v1667405741/chproyecta/proyectos/durazno/wtbtqdwbwra29goovo7d.jpg'},
    {id: 'qftktuvlnyaxzvv5j45q',url: 'https://res.cloudinary.com/carlos-hm/image/upload/v1667406858/chproyecta/proyectos/picacho/qftktuvlnyaxzvv5j45q.jpg'},
  ];

  return (
    <Swiper
      modules={[Keyboard, Navigation, Autoplay]}
      navigation={{
        nextEl: '.swiper-custom-next', 
        prevEl: '.swiper-custom-prev'
      }}
      keyboard={{
        enabled: true,
        onlyInViewport: false,
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      spaceBetween={0}
      slidesPerView={1}
      // onSwiper={onSwiper}
      css={{
        height:'75vh',
        transition: 'height ease .4s',
        cursor: 'grab',
        '.swiper-wrapper': { 
          alignItems: 'center'
        },
        '& .swiper-custom-prev, & .swiper-custom-next': {
          position: 'absolute',
          bottom: '14px',
          zIndex: '10',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .swiper-custom-prev': {
          right: '60px',
        }, 
        '& .swiper-custom-next': {
          right: '14px',
        },
        '& .swiper-custom-prev.swiper-button-disabled, & .swiper-custom-next.swiper-button-disabled': {
          opacity: '.35',
          cursor: 'auto',
          pointerEvents: 'none',
        },
        [mq.medium]: {
          maxHeight: '500px',
        },
        [mq.large]: {
          height: '80vh',
          maxHeight: '650px',
        },
      }}
    >
      {photos.map(photo => {
        return (
          <SwiperSlide key={photo.id}>
            <div css={{width: '100%', height: '100%'}} className='swiper-zoom-container'>
              <img css={{width: '100%', height: '100%', objectFit: 'cover'}} src={photo.url} alt={`Foto`}/>
            </div>
          </SwiperSlide>
        )
      })}
      <ButtonCircle className="swiper-custom-prev">
        <IconArrowLeft fill={colors.white}/>
      </ButtonCircle>
      <ButtonCircle className="swiper-custom-next">
          <IconArrowRight fill={colors.white}/>
      </ButtonCircle>
    </Swiper>
  )
} 
/** @jsx jsx */
import { jsx } from "@emotion/core"
import projectsData from "../data/projects-data.json"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { H2, H4, P } from "../components/lib";
import {  ProjectsYear } from "../components/project";
import { ScrollRestoration } from "react-router-dom";
import * as mq from '../styles/media-queries';
import * as colors from '../styles/colors';
import { CardProject } from "../components/cards";

const ProjectsScreen = () => {
  const publicProjects = projectsData.filter(project => project.public);
  const projectsByDate = Object.entries(publicProjects
    .reduce((acc, current) => {
      const year = new Date(current.status.dateEnd).getFullYear()
      acc[year] ? acc[year] = [...acc[year], current] : acc[year] = [current]
      return acc;
    }, {})
    ).sort((a, b) => b[0] - a[0])


  return (
    <div>
      <ScrollRestoration/>
      <Header/>
      <div css={{
        maxWidth: '1200px',
        margin: '0 auto',
      }}>
        <div css={{
          maxWidth: '820px',
          maxHeight: '350px',
          marginTop: '8vh',
          padding: '0 30px',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          '& > h2' :{
            marginBottom: '20px',
          },
          [mq.medium]: {
            padding: '0 50px',
            '& > p': {
              fontSize: '20px',
            }
          }
        }}>
          <H2 size="40px">Nuestros proyectos.</H2>
          <P>Cada proyecto es único así como la experiencia existencial que genera. Sus espacios finamente diseñados son una mezcla de privacidad, naturaleza y urbanismo.</P>
        </div>
        <div css={{
          [mq.medium]:{display: 'none'}
          }}>
          { projectsByDate.map(([year, projects]) => <ProjectsYear key={year} projects={projects} year={year}/>)}
        </div>
        <section css={{
          display: 'none',
          [mq.medium]: {display: 'grid'},
          gridTemplateColumns: '1fr 1fr 1fr',
          rowGap: '5vw',
          justifyItems: 'center',
          padding: '0 20px 80px',
          '.card-project': {
              maxHeight: '340px',
          },
          [mq.xLarge]: {
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
          }
        }}>
          { projectsByDate.map(([year, projects], projectsIndex, porjectsArr) => {
              return (
                projects.map((project, i, arr) => {
                  return (
                    <div key={project.id} css={{position: 'relative'}}>
                      {<H4 size ="16px" 
                        css={[
                          i >= 1 && {opacity: '0'},
                          {marginBottom: '20px'}  
                      ]}>
                      {year}</H4>}
                      <CardProject project={project} size="large" desktop='1'/>
                      {i === arr.length - 1 && projectsIndex !== porjectsArr.length - 1 && <div css={{
                        backgroundColor: colors.gray10,
                        width: '2px',
                        height:' 60%',
                        position: 'absolute',
                        top: '26%',
                        left: '110%',
                      }}></div>}
                    </div>
                  )
                })
              )
            })
          }
        </section>
      </div>
      <Footer/>
    </div>
  )
};

export default ProjectsScreen;